import { render, staticRenderFns } from "./orgConsumption.vue?vue&type=template&id=4707b67a&scoped=true&"
import script from "./orgConsumption.vue?vue&type=script&lang=js&"
export * from "./orgConsumption.vue?vue&type=script&lang=js&"
import style0 from "./orgConsumption.vue?vue&type=style&index=0&id=4707b67a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4707b67a",
  null
  
)

export default component.exports